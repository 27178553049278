import { AuthorizedLayout } from '../layouts/AuthorizedLayout';
import NotAuthorizedLayout from '../layouts/NotAuthorizedLayout';
import AcademicStructure from '../views/AcademicStructure';
import PeriodRecordCreate from '../views/AcademicStructure/periods/PeriodRecordCreate';
import PeriodRecordEdit from '../views/AcademicStructure/periods/PeriodRecordEdit';
import ScheduleRecordCreate from '../views/AcademicStructure/schedule/ScheduleRecordCreate';
import ScheduleRecordEdit from '../views/AcademicStructure/schedule/ScheduleRecordEdit';
import { PermissionName } from '../types/Auth';
import AcademicRecord from '../views/AcademicRecord';
import TuitionProcessOnSite from '../views/AdmissionAndEnrollments/OnSiteEnrollment/TuitionProcessSearchStudent/TuitionProcessOnSite';
import { SearchStudentOnSite } from '../views/AdmissionAndEnrollments/OnSiteEnrollment/TuitionProcessSearchStudent/onSite/SearchStudentOnSite';
import TariffAndTuition from '../views/AdmissionAndEnrollments/TariffAndTuition';
import AdmissionsList from '../views/AdmissionAndEnrollments/AdmissionManagement/AdmissionsList';
import AdmissionsListWithTabs from '../views/AdmissionAndEnrollments/AdmissionManagement/AdmissionsListWithTabs';
import AdmissionsPendingList from '../views/AdmissionAndEnrollments/AdmissionManagement/AdmissionsPendingList';
import ChangePassword from '../views/ChangePassword/ChangePassword';
import ClosingPeriod from '../views/ClosingPeriod/ClosingPeriod';
import Configurations from '../views/Configurations/Configurations';
import UpdateCourseRegistration from '../views/CourseRegistration/CourseRegistration';
import DetailCourseRegistration from '../views/CourseRegistration/DetailCourseRegistration';
import CourseValidationInternal from '../views/CourseValidationInternal';
import CourseValidationInternalStudent from '../views/CourseValidationInternalStudent';
import CurriculumProgress from '../views/CurriculumProgress';
import CurriculumProgressStudent from '../views/CurriculumProgressStudent';
import GraduatesList from '../views/GraduatesList/GraduatesList';
import GraduatesListProcessMassive from '../views/GraduatesListProcessMassive/GraduatesListProcessMassive';
import GraduationProcessMasive from '../views/GraduationProcessMasive/GraduationProcessMasive';
import { GraduationProcessSingle } from '../views/GraduationProcessSingle/GraduationProcessSingle';
import GraduationProcessSinglePendingRequirerments from '../views/GraduationProcessSinglePendingRequirerments/GraduationProcessSinglePendingRequirerments';
import InterimDegreeCurriculums from '../views/academicOffers/InterimDegree/Curriculums';
import InterimDegreeCreate from '../views/academicOffers/InterimDegree/InterimDegreeCreate';
import InterimDegreeEdit from '../views/academicOffers/InterimDegree/InterimDegreeEdit';
import InterimDegreeShow from '../views/academicOffers/InterimDegree/InterimDegreeShow';
import InterimDegreeInterimDegrees from '../views/academicOffers/InterimDegree/InterimDegrees';
import Login from '../views/Login/Login';
import PackagesList from '../views/Packages/PackagesList';
import PackagesPendingStudents from '../views/Packages/PackagesPendingStudents';
import PackagesView from '../views/Packages/PackagesView';
import SectionsAndPackagesList from '../views/PackagesMaintainer/SectionsAndPackagesList';
import SectionsAndPackagesView from '../views/PackagesMaintainer/SectionsAndPackagesView';
import PaymentHub from '../views/PaymentHub';
import Reports from '../views/Reports/Reports';
import SectionAttributesList from '../views/SectionAttributes/SectionAttributesList';
import SectionAttributesView from '../views/SectionAttributes/SectionAttributesView';
import SectionUpdate from '../views/SectionsMaintainer/SectionUpdate';
import SectionsMaintainer from '../views/SectionsMaintainer/SectionsMaintainer';
import CreateSectionForm from '../views/SectionsMaintainer/parts/CreateSectionForm';
import ChangeStudyPlanList from '../views/StudentManagement/EnrollmentSoftChange';
import ChangePeriodOrSchedule from '../views/StudentManagement/EnrollmentSoftChange/EnrollmentSoftChange';
import ContinuityChangeStudyPlan from '../views/StudentManagement/EnrollmentSoftChange/EnrollmentSoftChangeContinuity';
import StudentFile from '../views/StudentManagement/StudentFile';
import UpdateStudentFile from '../views/StudentManagement/StudentFile/update';
import StudentStatus from '../views/StudentStatus';
import StudentsDegree from '../views/StudentsDegree';
import StudentsDegreeStudent from '../views/StudentsDegreeStudent';
import StudentsPetitionsDetail from '../views/StudentsPetitionsDetail';
import StudentPetitions from '../views/StudentsPetitionsList';
import { StudentsPostCloseDetail } from '../views/StudentsPostCloseDetail/StudentsPostCloseDetail';
import { StudentsPostCloseSearch } from '../views/StudentsPostCloseSearch/StudentsPostCloseSearch';
import TeacherActivation from '../views/TeacherActivation/TeacherActivation';
import TuitionContinuityProcessDocumentReview from '../views/TuitionContinuityProcessDocumentReview';
import TuitionContinuityProcessDocumentReviewDetail from '../views/TuitionContinuityProcessDocumentReviewDetail';
import TuitionContinuityProcessDocumentReviewDocuments from '../views/TuitionContinuityProcessDocumentReviewDocuments';
import TuitionContinuityProcessManagement from '../views/TuitionContinuityProcessManagement/TuitionContinuityProcessManagement';
import TuitionContinuityProcessManagementDetail from '../views/TuitionContinuityProcessManagementDetail/TuitionContinuityProcessManagementDetail';
import TuitionContinuityTariffAndTuition from '../views/TuitionContinuityTariffAndTuition';
import UsersAndRoles from '../views/UsersAndRoles';
import RoleManage from '../views/UsersAndRoles/Role/RoleManage';
import RolePermission from '../views/UsersAndRoles/Role/RoleManage/parts/RolePermission';
import UserNew from '../views/UsersAndRoles/User/UserNew/UserNew';
import CoursesMaintainer from '../views/academicOffers/courses';
import CourseUpdate from '../views/academicOffers/courses/CourseUpdate';
import StudyPlansList from '../views/academicOffers/studyPlans';
import CurriculumCreate from '../views/academicOffers/studyPlans/curriculum/views/CurriculumCreate';
import CurriculumCreateMention from '../views/academicOffers/studyPlans/curriculum/views/CurriculumCreateMention';
import CurriculumEdit from '../views/academicOffers/studyPlans/curriculum/views/CurriculumEdit';
import CurriculumEditMetion from '../views/academicOffers/studyPlans/curriculum/views/CurriculumEditMetion';
import CurriculumListMention from '../views/academicOffers/studyPlans/curriculum/views/CurriculumListMention';
import CurriculumView from '../views/academicOffers/studyPlans/curriculum/views/CurriculumView';
import CurriculumViewMetion from '../views/academicOffers/studyPlans/curriculum/views/CurriculumViewMetion';
import StudyPlanRecordCreate from '../views/academicOffers/studyPlans/parts/StudyPlanRecordCreate';
import StudyPlanRecordEdit from '../views/academicOffers/studyPlans/parts/StudyPlanRecordEdit';
import VersionRecordCreate from '../views/academicOffers/studyPlans/version/parts/VersionRecordCreate';
import AcademicOffersList from '../views/academicOffers/vacant-offers';
import AcademicOffersCreate from '../views/academicOffers/vacant-offers/AcademicOffersCreate';
import AcademicOffersEdit from '../views/academicOffers/vacant-offers/AcademicOffersEdit';
import AcademicOffersView from '../views/academicOffers/vacant-offers/AcademicOffersView';
import { DocumentsGridCards } from '../views/documents/DocumentsGridCards';
import { DocumentsList } from '../views/documents/DocumentsList';
import PeriodEndReview from '../views/periodEndReview/PeriodEndReview';
import { SHOW_PAYMENT_HUB } from './constants';
import InterimDegreeGraduationProcessMassive from '../views/InterimDegreeGraduationProcess/Massive';
import InterimDegreeGraduationProcessStudents from '../views/InterimDegreeGraduationProcess/ProcessStudents';
import InterimDegreeGraduationStudents from '../views/InterimDegreeGraduationProcess/Students';
import InterimDegreeGraduationProcessSingle from '../views/InterimDegreeGraduationProcess/Single';
import InterimDegreeGraduationProcessFailedResumen from '../views/InterimDegreeGraduationProcess/FailedResumen';

export enum PathsLayouts {
  auth = '/auth',
  academicStructure = '/maintainers',
  academicOffers = '/academic-offers',
  tuitionProcess = '/tuition-process',
  tariffAndTuiton = '/tariff-tuiton',
  admissions = '/admissions',
  documents = '/documents',
  packages = '/packages',
  packagesMaintainer = '/packages-maintainer',
  reports = '/reports',
  courseRegistration = '/course-registration',
  sectionAttributes = '/section-attributes',
  sectionsMaintainer = '/sections-maintainer',
  teacherActivation = '/teacher-activation',
  studentStatus = '/student-status',
  studentFile = '/student-file',
  curriculumProgress = '/avance-curricular',
  closingPeriod = '/closing-period',
  configurations = '/config',
  studentsPostClose = '/students-post-close',
  tuitionContinuityProcessManagement = '/tuition-continuity-process-management',
  graduationProcessMassive = '/graduation-process-massive',
  graduationProcessSingle = '/graduation-process-single',
  graduatesList = '/graduates-list',
  tuitionContinuityTariffAndTuition = '/tuition-continuity-tariff-tuition',
  tuitionContinuityDocumentReview = '/tuition-continuity-document-review',
  courseValidationInternal = '/convalidacion-interna',
  academicRecord = '/record-academico',
  paymentHub = '/centro-pagos',
  studentPetitions = '/student-petitions',
  studentsDegree = '/students-degree',
  enrollmentSoftChange = '/enrollment-soft-change',
  usersAndRoles = '/users-and-roles',
  interimDegreeGraduationProcess = '/interim-degree-graduation-process',
}

export type ViewRouteType = {
  path: string;
  component: () => JSX.Element;
  requiredPermissions?: PermissionName[];
};

export type RoutesByLayout = {
  // this keys are the paths of every layout
  [key in PathsLayouts]?: {
    layoutComponent: () => JSX.Element;
    defaultPath: string;
    views: ViewRouteType[];
    requiredPermissions?: PermissionName[];
    allPermissionsRequired?: boolean;
    disabled?: boolean;
  };
};

/**
 * Contiene todos los layouts con sus vistas
 */
export const NOT_AUTHORIZED_ROUTES: RoutesByLayout = {
  [PathsLayouts.auth]: {
    layoutComponent: NotAuthorizedLayout,
    defaultPath: '/login',
    views: [
      {
        path: '/login',
        component: Login,
      },
      {
        path: '/activate-account',
        component: ChangePassword,
      },
      {
        path: '/reset-password',
        component: ChangePassword,
      },
    ],
  },
};

export const AUTHORIZED_ROUTES: RoutesByLayout = {
  [PathsLayouts.academicStructure]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/periods',
    requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],
    views: [
      {
        path: '/periods',
        component: AcademicStructure,
      },
      {
        path: '/periods/record',
        component: PeriodRecordCreate,
      },
      {
        path: '/periods/record/:id',
        component: PeriodRecordEdit,
      },
      {
        path: '/campus',
        component: AcademicStructure,
      },
      {
        path: '/schools',
        component: AcademicStructure,
      },
      {
        path: '/schedules',
        component: AcademicStructure,
      },
      {
        path: '/schedules/record',
        component: ScheduleRecordCreate,
      },
      {
        path: '/schedules/record/:id',
        component: ScheduleRecordEdit,
      },
      {
        path: '/modalities',
        component: AcademicStructure,
      },
    ],
  },

  [PathsLayouts.academicOffers]: {
    //requiredPermissions: [PermissionName.ACADEMIC_STRUCTURE],

    layoutComponent: AuthorizedLayout,
    defaultPath: '/study-plans',
    views: [
      //cursos
      {
        path: '/curricular-subjects',
        component: CoursesMaintainer,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_CURRICULAR_SUBJECT],
      },
      {
        path: '/curricular-subjects/create',
        component: CourseUpdate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_CURRICULAR_SUBJECT],
      },
      {
        path: '/curricular-subjects/watch/:id',
        component: CourseUpdate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_CURRICULAR_SUBJECT],
      },
      {
        path: '/curricular-subjects/update/:id',
        component: CourseUpdate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_CURRICULAR_SUBJECT],
      },
      //Planes de estudios
      {
        path: '/study-plans',
        component: StudyPlansList,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record',
        component: StudyPlanRecordCreate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record/:id',
        component: StudyPlanRecordEdit,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record/:id/version/create',
        component: VersionRecordCreate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record/:id/version/:versionId/curriculum/create', //Create
        component: CurriculumCreate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path:
          '/study-plans/record/:id/version/:versionId/curriculum/:curriculumId', //Edit
        component: CurriculumEdit,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path:
          '/study-plans/record/:id/version/:versionId/curriculum/:curriculumId/view', //View
        component: CurriculumView,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record/:id/version/:versionId/mention/create', //Create
        component: CurriculumCreateMention,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record/:id/version/:versionId/mention/list', //List
        component: CurriculumListMention,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path: '/study-plans/record/:id/version/:versionId/mention/:mentionId', //Edit
        component: CurriculumEditMetion,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      {
        path:
          '/study-plans/record/:id/version/:versionId/mention/:mentionId/:action', //View
        component: CurriculumViewMetion,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_STUDY_PLAN],
      },
      //Offertas Vacantes
      {
        path: '/vacant-offers',
        component: AcademicOffersList,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_VACANT_OFFER],
      },
      {
        path: '/vacant-offers/record',
        component: AcademicOffersCreate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_VACANT_OFFER],
      },
      {
        path: '/vacant-offers/record/:id',
        component: AcademicOffersEdit,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_VACANT_OFFER],
      },
      {
        path: '/vacant-offers/info/:id',
        component: AcademicOffersView,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_VACANT_OFFER],
      },
      // Titulos intermedios
      {
        path: '/interim-degree/create/:curriculumId',
        component: InterimDegreeCreate,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE],
      },
      {
        path: '/interim-degree/show/:interimDegreeId',
        component: InterimDegreeShow,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE],
      },
      {
        path: '/interim-degree/edit/:interimDegreeId',
        component: InterimDegreeEdit,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE],
      },
      {
        path: '/interim-degree/curriculums',
        component: InterimDegreeCurriculums,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE],
      },
      {
        path: '/interim-degree/curriculums/:curriculumId',
        component: InterimDegreeInterimDegrees,
        requiredPermissions: [PermissionName.ACADEMIC_OFFER_INTERIM_DEGREE],
      },
    ],
  },
  [PathsLayouts.tuitionProcess]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.ADMISSIONS_AND_ENROLLMENTS_TUITION_PROCESS,
    ],
    views: [
      {
        path:
          '/postulation/:postulantId/:postulationDetailId/:admissionType/:fromStart',
        component: TuitionProcessOnSite,
      },
      {
        path: '/',
        component: SearchStudentOnSite,
      },
    ],
  },
  [PathsLayouts.tariffAndTuiton]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/tuitions',
    requiredPermissions: [
      PermissionName.ADMISSIONS_AND_ENROLLMENTS_TARIFFS_AND_DISCOUNTS,
    ],
    views: [
      {
        path: '/tuitions',
        component: TariffAndTuition,
      },
      {
        path: '/tariff',
        component: TariffAndTuition,
      },
      {
        path: '/discounts',
        component: TariffAndTuition,
      },
    ],
  },
  [PathsLayouts.admissions]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.ADMISSIONS_AND_ENROLLMENTS_ADMISSION_MANAGEMENT,
    ],
    views: [
      {
        path: '/',
        component: AdmissionsList,
      },
      {
        path: '/postulans-pending',
        component: AdmissionsPendingList,
      },
      {
        path: '/:id/in-process',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/enrolled',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/dropped',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/waiting-list',
        component: AdmissionsListWithTabs,
      },
      {
        path: '/:id/retracted',
        component: AdmissionsListWithTabs,
      },
    ],
  },
  [PathsLayouts.documents]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [
      PermissionName.ADMISSIONS_AND_ENROLLMENTS_DOCUMENTATION_REVIEW_PROCESS,
    ],
    views: [
      {
        path: '/',
        component: DocumentsGridCards,
      },
      {
        path: '/:academicOfferStudyPlanVersionOffersId',
        component: DocumentsList,
      },
    ],
  },
  [PathsLayouts.packages]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SECTIONS_PACKAGES_ALL],
    views: [
      {
        path: '/pending-students/:id',
        component: PackagesPendingStudents,
      },
      {
        path: '/:id',
        component: PackagesView,
      },
      {
        path: '/',
        component: PackagesList,
      },
    ],
  },
  [PathsLayouts.packagesMaintainer]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SECTION_PACKAGES_LOAD_ALL],
    views: [
      {
        path: '/',
        component: SectionsAndPackagesList,
      },
      { path: '/:periodId', component: SectionsAndPackagesView },
    ],
  },
  [PathsLayouts.courseRegistration]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.ENROLLMENT_MODIFICATION],
    views: [
      { path: '/', component: UpdateCourseRegistration },
      {
        path: '/:accountId/:studyPlanEnrollmentId',
        component: DetailCourseRegistration,
      },
    ],
  },
  [PathsLayouts.reports]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.REPORTS],
    views: [
      {
        path: '/',
        component: Reports,
      },
    ],
  },
  [PathsLayouts.sectionAttributes]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SECTION_PACKAGES_LOAD_ALL],
    views: [
      {
        path: '/',
        component: SectionAttributesList,
      },
      { path: '/:periodId', component: SectionAttributesView },
    ],
  },
  [PathsLayouts.sectionsMaintainer]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SECTIONS_MODIFICATION],
    views: [
      { path: '/create', component: CreateSectionForm },
      { path: '/:sectionId', component: SectionUpdate },
      { path: '/', component: SectionsMaintainer },
    ],
  },
  [PathsLayouts.teacherActivation]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.TEACHERS_ACTIVATION_ALL],
    views: [
      {
        path: '/',
        component: TeacherActivation,
      },
    ],
  },
  [PathsLayouts.usersAndRoles]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/users',
    requiredPermissions: [PermissionName.MANAGEMENT_TOOLS_USERS_AND_ROLES],
    views: [
      { path: '/users', component: UsersAndRoles },
      { path: '/users/create/:id?', component: UserNew },
      { path: '/users/:id/update', component: UserNew },
      { path: '/roles', component: UsersAndRoles },
      { path: '/roles/create/', component: RoleManage },
      { path: '/roles/:roleId/update', component: RoleManage },
      { path: '/roles/:roleId/permissions', component: RolePermission },
    ],
  },
  [PathsLayouts.studentStatus]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_STUDENT_STATUS],
    views: [
      {
        path: '/',
        component: StudentStatus,
      },
    ],
  },
  [PathsLayouts.studentFile]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_STUDENT_FILE],
    views: [
      {
        path: '/',
        component: StudentFile,
      },
      {
        path: '/update/:postulantId',
        component: UpdateStudentFile,
      },
    ],
  },
  [PathsLayouts.curriculumProgress]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_CURRICULUM_PROGRESS_LIST],
    views: [
      {
        path: '/',
        component: CurriculumProgress,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: CurriculumProgressStudent,
      },
    ],
  },
  [PathsLayouts.closingPeriod]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.SUBJECT_SECTIONS_PERIOD_CLOSE],
    views: [
      {
        path: '/',
        component: ClosingPeriod,
      },
      {
        path: '/:id',
        component: PeriodEndReview,
      },
    ],
  },
  [PathsLayouts.configurations]: {
    layoutComponent: AuthorizedLayout,
    requiredPermissions: [PermissionName.SETTINGS],
    defaultPath: '/',
    views: [{ path: '/', component: Configurations }],
  },
  [PathsLayouts.studentsPostClose]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENTS_POST_CLOSE],
    views: [
      { path: '/', component: StudentsPostCloseSearch },
      { path: '/detail', component: StudentsPostCloseDetail },
    ],
  },
  [PathsLayouts.tuitionContinuityProcessManagement]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.TUITION_CONTINUITY_MANAGEMENT],
    views: [
      { path: '/', component: TuitionContinuityProcessManagement },
      {
        path: '/:studyPlanVersionOfferId/period/:periodId',
        component: TuitionContinuityProcessManagementDetail,
      },
    ],
  },
  [PathsLayouts.graduationProcessMassive]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_GRADUATION_PROCESS],
    views: [
      { path: '/', component: GraduationProcessMasive },
      { path: '/graduates-list', component: GraduatesListProcessMassive },
    ],
  },
  [PathsLayouts.graduationProcessSingle]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_GRADUATION_PROCESS],
    views: [
      { path: '/', component: GraduationProcessSingle },
      {
        path: '/pending-requirerments',
        component: GraduationProcessSinglePendingRequirerments,
      },
    ],
  },
  [PathsLayouts.graduatesList]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_GRADUATION_PROCESS],
    views: [{ path: '/', component: GraduatesList }],
  },
  [PathsLayouts.tuitionContinuityTariffAndTuition]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/tuitions',
    requiredPermissions: [PermissionName.TUITION_CONTINUITY_TARIFF_AND_TUITION],
    views: [
      { path: '/tuitions', component: TuitionContinuityTariffAndTuition },
      {
        path: '/tariff',
        component: TuitionContinuityTariffAndTuition,
      },
    ],
  },
  [PathsLayouts.tuitionContinuityDocumentReview]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.TUITION_CONTINUITY_DOCUMENT_REVIEW],
    views: [
      { path: '/', component: TuitionContinuityProcessDocumentReview },
      {
        path: '/student-list/:periodId/:offerId/:status',
        component: TuitionContinuityProcessDocumentReviewDetail,
      },
      {
        path: '/documents/:tcProcessId',
        component: TuitionContinuityProcessDocumentReviewDocuments,
      },
    ],
  },
  [PathsLayouts.courseValidationInternal]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.COURSE_VALIDATION],
    views: [
      {
        path: '/',
        component: CourseValidationInternal,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: CourseValidationInternalStudent,
      },
    ],
  },
  [PathsLayouts.academicRecord]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    requiredPermissions: [PermissionName.ACADEMIC_RECORD],
    views: [
      {
        path: '/',
        component: AcademicRecord,
      },
    ],
  },
  [PathsLayouts.paymentHub]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/',
    disabled: !SHOW_PAYMENT_HUB,
    requiredPermissions: [PermissionName.PAYMENT_HUB],
    views: [
      {
        path: '/',
        component: PaymentHub,
      },
    ],
  },
  [PathsLayouts.studentPetitions]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/received',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_PETITIONS],
    views: [
      {
        path: '/received',
        component: StudentPetitions,
      },
      {
        path: '/resolved',
        component: StudentPetitions,
      },
      {
        path: '/:petitionId',
        component: StudentsPetitionsDetail,
      },
    ],
  },
  [PathsLayouts.enrollmentSoftChange]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/list',
    requiredPermissions: [
      PermissionName.STUDENT_MANAGEMENT_ENROLLMENT_SOFT_CHANGE,
    ],
    views: [
      {
        path: '/list',
        component: ChangeStudyPlanList,
      },
      {
        path: '/list-continuity',
        component: ChangeStudyPlanList,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: ChangePeriodOrSchedule,
      },
      {
        path: '/continuity/:studyPlanEnrollmentId',
        component: ContinuityChangeStudyPlan,
      },
    ],
  },
  [PathsLayouts.studentsDegree]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/graduate',
    requiredPermissions: [PermissionName.STUDENT_MANAGEMENT_STUDENTS_DEGREE],
    views: [
      {
        path: '/graduate',
        component: StudentsDegree,
      },
      {
        path: '/titled',
        component: StudentsDegree,
      },
      {
        path: '/:studyPlanEnrollmentId',
        component: StudentsDegreeStudent,
      },
    ],
  },
  [PathsLayouts.interimDegreeGraduationProcess]: {
    layoutComponent: AuthorizedLayout,
    defaultPath: '/massive',
    requiredPermissions: [PermissionName.INTERIM_DEGREE_GRADUATION_PROCESS_ALL],
    views: [
      {
        path: '/massive',
        component: InterimDegreeGraduationProcessMassive,
      },
      {
        path: '/single',
        component: InterimDegreeGraduationProcessSingle,
      },
      {
        path: '/students',
        component: InterimDegreeGraduationStudents,
      },
      {
        path: '/failed/:studyPlanEnrollmentId/:interimDegreeId',
        component: InterimDegreeGraduationProcessFailedResumen,
      },
      {
        path: '/:processId/:batch',
        component: InterimDegreeGraduationProcessStudents,
      },
    ],
  },
};

export const routes = { ...AUTHORIZED_ROUTES, ...NOT_AUTHORIZED_ROUTES };
